import React, { createContext, useMemo, useState } from 'react'

export interface PaginationContext {
  feedPage: number
  updateFeedPage: (page: number) => void
  lastFeedQuery: object
  updateLastFeedQuery: (query: object) => void
  productFeedPage: number
  updateProductFeedPage: (page: number) => void
  lastProductFeedQuery: string
  updateLastProductFeedQuery: (query: string) => void
}

const initialContext: PaginationContext = {
  feedPage: 0,
  updateFeedPage: () => {},
  lastFeedQuery: {},
  updateLastFeedQuery: () => {},
  productFeedPage: 0,
  updateProductFeedPage: () => {},
  lastProductFeedQuery: '',
  updateLastProductFeedQuery: () => {},
}

export const PaginationContext = createContext<PaginationContext>(initialContext)

const PaginationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [feedPage, setFeedPage] = useState<number>(0)
  const updateFeedPage = (page: number): void => {
    setFeedPage(page)
  }

  const [lastFeedQuery, setLastFeedQuery] = useState<object>({})
  const updateLastFeedQuery = (query: object): void => {
    setLastFeedQuery(query)
  }

  const [productFeedPage, setProductFeedPage] = useState<number>(0)
  const updateProductFeedPage = (page: number): void => {
    setProductFeedPage(page)
  }

  const [lastProductFeedQuery, setLastProductFeedQuery] = useState<string>('')
  const updateLastProductFeedQuery = (query: string): void => {
    setLastProductFeedQuery(query)
  }

  const value = useMemo(
    () => ({
      feedPage,
      updateFeedPage,
      lastFeedQuery,
      updateLastFeedQuery,
      productFeedPage,
      updateProductFeedPage,
      lastProductFeedQuery,
      updateLastProductFeedQuery,
    }),
    [feedPage, lastFeedQuery, lastProductFeedQuery, productFeedPage],
  )

  return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>
}

export default PaginationProvider
