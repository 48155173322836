import { createAsyncThunk } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UserIdentifier } from '@centrito/api/nest/shopper/cart/domain/types/user-identifier'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const revalidateCoupon = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
    isDeactivatedCoupon: boolean
  },
  {
    campaignId: string
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/revalidateCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const anonymousId = currentState.userData.anonymousUserId
  const userId = currentState.auth.authenticatedData?.userId
  const cartOwner = !isNil(userId)
    ? ({ type: 'AUTHENTICATED', userId } satisfies UserIdentifier)
    : ({ type: 'ANONYMOUS', anonymousId: anonymousId! } satisfies UserIdentifier)
  const { newCart: updatedCart, isDeactivatedCoupon } =
    await trpcProxyClient.user.cart.refreshDiscountData.mutate(cartOwner)
  return { updatedCart, isDeactivatedCoupon }
})
export default revalidateCoupon
