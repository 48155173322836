import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UserIdentifier } from '@centrito/api/nest/shopper/cart/domain/types/user-identifier'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface GetUserCartResponse {
  updatedCart: CartExtendedPublicComposite
}

const getUserCart = createAsyncThunk<GetUserCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/getUserCart`,
  async (payload, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      const userId = currentState.auth.authenticatedData?.userId
      const anonymousId = currentState.userData.anonymousUserId
      const cartOwner = !isNil(userId)
        ? ({ userId, type: 'AUTHENTICATED' } satisfies UserIdentifier)
        : ({ anonymousId: anonymousId!, type: 'ANONYMOUS' } satisfies UserIdentifier)

      const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)

      // if the cart has no coupon, just return the cart
      if (isNil(newCart.cart.discountData)) {
        return {
          updatedCart: newCart,
        }
      }

      const { newCart: updatedCart } = await trpcProxyClient.user.cart.refreshDiscountData.mutate(
        cartOwner,
      )

      return {
        updatedCart,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default getUserCart
