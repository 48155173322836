import { base } from 'antd-mobile/es/locales/base'
import { mergeLocale } from 'antd-mobile/es/utils/merge-locale'

const esES = mergeLocale(base, {
  Form: {
    optional: 'Opcional',
  },
})

export default esES
