import { useEffect } from 'react'
import React from 'react'
import NetInfo from '@react-native-community/netinfo'
import { useToastController } from '@tamagui/toast'
import isNil from 'lodash.isnil'

const NetInfoProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [hasInternetConnection, setHasInternetConnection] = React.useState<null | boolean>(null)
  const { show: showToast } = useToastController()

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      const newHasInternetConnectionValue = state.isConnected && state.isInternetReachable

      if (!isNil(newHasInternetConnectionValue)) {
        if (newHasInternetConnectionValue === false) {
          showToast('❌ No hay conexión a internet')
        } else if (hasInternetConnection === false) {
          showToast('✅ Conexión a internet restablecida')
        }

        setHasInternetConnection(newHasInternetConnectionValue)
      }
    })

    return (): void => unsubscribe()
  }, [hasInternetConnection, showToast])

  return <>{children}</>
}

export default NetInfoProvider
