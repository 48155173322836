import posthog from 'posthog-js'
import Sentry from '@centrito/app/sentry/shopper'
import { type PosthogClient } from '@centrito/app/utils/services/analytics/posthog'
import type { CustomEventProperties } from './common'
import { handleSharedEvents } from './sharedEvents'

export default function (
  this: PosthogClient,
  eventName: string,
  customEventProperties?: CustomEventProperties,
  isEnabled: boolean = this.analyticsEnabled,
): void {
  if (!isEnabled || !this.clientEnabled) return

  let eventProperties = handleSharedEvents(eventName, customEventProperties)

  if (!eventProperties) {
    eventProperties = {}
    switch (eventName) {
      case 'shopper_web_product_list_tab_bar_press':
        eventProperties.tabName = customEventProperties?.productListTabName
        break

      case 'shopper_download_popup_button_pressed':
      case 'shopper_download_popup_closed':
      case 'shopper_download_header_button_pressed':
      case 'shopper_download_header_closed':
      case 'shopper_pageview_checkout_payment':
        break

      default:
        Sentry.captureException('Posthog: Invalid Custom Event', {
          extra: { eventName, customEventProperties },
        })
        return
    }
  }

  posthog.capture(eventName, eventProperties)
}
