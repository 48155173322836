import type { JSX, ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

interface HistoryContextValue {
  historyStack: string[]
  canGoBack: boolean
}

const HistoryContext = createContext<HistoryContextValue>({
  historyStack: [],
  canGoBack: false,
})

export const HistoryProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const router = useRouter()
  const [historyStack, setHistoryStack] = useState<string[]>([router.asPath])

  useEffect(() => {
    let isBackNavigation = false

    const handleRouteChangeComplete = (url: string): void => {
      if (isBackNavigation) {
        // Back navigation occurred; do not push the URL onto the stack
        isBackNavigation = false
      } else {
        setHistoryStack((prevStack) => {
          if (prevStack[prevStack.length - 1] !== url) {
            return [...prevStack, url]
          }
          return prevStack
        })
      }
    }

    const handleBeforePopState = (): boolean => {
      if (historyStack.length > 1) {
        setHistoryStack((prevStack) => prevStack.slice(0, -1))
        isBackNavigation = true
        return true
      } else {
        router.push('/')
        setHistoryStack(['/'])
        isBackNavigation = true
        return false
      }
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.beforePopState(handleBeforePopState)

    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.beforePopState(() => true) // Reset to default behavior
    }
  }, [historyStack, router])

  const canGoBack = historyStack.length > 1

  const value = useMemo(() => ({ historyStack, canGoBack }), [historyStack, canGoBack])

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
}

export const useHistory = (): HistoryContextValue => useContext(HistoryContext)
