import React from 'react'
import { Text, type TextProps } from 'tamagui'

export const ContentStandardText: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontFamily="$latoFont" fontSize={14} fontWeight="400" letterSpacing={0} {...rest}>
      {children}
    </Text>
  )
}
