import isNil from 'lodash.isnil'
import type { CustomEventProperties } from '@centrito/app/utils/notifications/shopper/utils/CustomEventProperties'
import {
  AddProductEventSchema,
  RegisterEventSchema,
} from '@centrito/app/utils/notifications/shopper/utils/CustomEventProperties'

type PostEventCallbackType = (eventName: string, attributes?: Record<string, string>) => void

const handleEventWithoutProperties = (
  eventName: string,
  postEventCallback: PostEventCallbackType,
): void => {
  switch (eventName) {
    case 'create_order':
      postEventCallback(eventName)
      break

    default:
      console.log('NotificationsClient: Invalid personalized event')
  }
}

const triggerCustomEvent = (
  eventName: string,
  postEventCallback: PostEventCallbackType,
  customEventProperties?: CustomEventProperties,
): void => {
  if (isNil(customEventProperties)) {
    handleEventWithoutProperties(eventName, postEventCallback)
    return
  }

  switch (eventName) {
    case 'add_product': {
      const addProductProperties = AddProductEventSchema.safeParse(customEventProperties)
      if (addProductProperties.success) {
        const { product_name } = addProductProperties.data
        postEventCallback(eventName, { product_name })
      }
      break
    }

    case 'register': {
      const registerProperties = RegisterEventSchema.safeParse(customEventProperties)
      if (registerProperties.success) {
        const { source, isMobile } = registerProperties.data
        postEventCallback(eventName, {
          source,
          isMobile: isMobile ? '1' : '0',
        })
      }
      break
    }

    default:
      console.log('NotificationsClient: Invalid personalized event')
  }
}

export default triggerCustomEvent
