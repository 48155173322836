import React from 'react'
import type { SVGProps } from 'react'

export const SamSamCloud: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={362}
    height={376}
    fill="none"
    viewBox="0 0 362 376"
    {...props}
  >
    <path
      fill="#DDE2FA80"
      d="m340.285 118.405 19.708 111.768.438 2.484c6.241 46.504-25.38 90.192-71.994 98.412-23.965 4.225-47.292-1.604-65.856-14.6l-.146.025c-12.982 17.806-32.522 30.893-55.902 35.015-23.964 4.226-47.463-1.724-66.027-14.72l-.146.025c-12.897 19.147-33.304 33.29-57.854 37.619-46.614 8.219-91.245-21.874-101.31-67.853l-.293.051-19.888-112.791C-93.257 112.899-37.117 35.662 44.662 26.063c11.819-1.33 23.577-1.295 34.837.184 1.1.258 2.32.344 3.541.43 8.01.998 16.254.75 24.583-.719 7.745-1.365 15.017-3.702 21.816-7.01 2.089-.971 4.152-2.088 6.069-3.18 12.043-6.04 25.273-10.481 39.301-12.955 77.446-13.656 151.504 38.063 165.184 115.644l.292-.052Z"
    />
  </svg>
)
