import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'
import omit from 'lodash.omit'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const removeCoupon = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
  },
  undefined,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/removeCoupon`, async (_payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const isAuthenticated = !isUndefined(currentState.auth.authenticatedData?.userId)

  if (isAuthenticated) {
    try {
      const resp = await trpcProxyClient.user.cart.removeCoupon.mutate()
      return {
        updatedCart: resp.cart,
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(miniSerializeError(e))
    }
  } else {
    const anonymousCart = currentState.userData.cart
    const updatedCart = {
      ...omit(anonymousCart.cart, ['discountData']),
      discountData: null,
    }

    if (isNil(updatedCart)) {
      return {
        updatedCart: anonymousCart,
      }
    }

    const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate({
      ...currentState.userData.cart,
      cart: updatedCart,
    })
    return {
      updatedCart: {
        ...currentState.userData.cart,
        cart: updatedCart,
        pricingData,
      } as CartExtendedPublicComposite,
    }
  }
})
export default removeCoupon
