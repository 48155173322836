import type { SVGProps } from 'react'
import React from 'react'

const WhatsAppIcon2: React.FC<SVGProps<SVGSVGElement>> = () => (
  <svg width="100%" height="100%" fill="none" viewBox="0 0 30 30">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M0 29.792l2.104-7.65a14.685 14.685 0 01-1.98-7.38C.129 6.622 6.782 0 14.959 0c3.935 0 7.71 1.557 10.491 4.327a14.734 14.734 0 014.343 10.447c-.004 8.139-6.659 14.762-14.834 14.762h-.006c-2.476 0-4.913-.618-7.088-1.797L0 29.792zm8.232-4.727l.45.266c1.9 1.12 4.067 1.71 6.275 1.71h.005c6.795 0 12.326-5.504 12.329-12.27.002-6.779-5.515-12.276-12.324-12.279-6.801 0-12.331 5.504-12.334 12.27-.004 2.31.65 4.573 1.885 6.53l.293.463-1.246 4.528 4.667-1.218zm2.186-17.111c.264.01.557.023.834.637.19.42.51 1.204.763 1.828.188.46.34.833.38.912.092.184.154.4.03.646l-.053.107c-.092.187-.16.326-.317.508-.062.072-.126.149-.19.226-.127.155-.255.31-.366.42-.186.184-.38.384-.163.753.216.37.96 1.577 2.062 2.555 1.185 1.053 2.215 1.497 2.736 1.722.102.044.184.08.244.11.371.184.587.153.803-.093.217-.246.927-1.077 1.174-1.446.247-.37.494-.308.834-.185.34.123 2.162 1.016 2.533 1.2.072.037.14.07.203.1.258.123.433.207.507.33.093.155.093.893-.216 1.755-.31.862-1.822 1.693-2.502 1.754a7.55 7.55 0 00-.197.021c-.628.074-1.42.167-4.25-.944-3.485-1.368-5.782-4.76-6.253-5.456a3.918 3.918 0 00-.079-.114l-.004-.005c-.2-.267-1.51-2.009-1.51-3.81 0-1.7.84-2.59 1.226-3l.072-.078c.25-.284.61-.452.989-.462h.04c.234 0 .466 0 .67.009z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default WhatsAppIcon2
