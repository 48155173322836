import { createBrowserClient } from '@supabase/ssr'
import isNil from 'lodash.isnil'
import { SUPABASE_ANON_KEY, SUPABASE_URL } from '@centrito/app/config'

export const getSupabaseBrowserClient = () => {
  if (isNil(SUPABASE_URL) || isNil(SUPABASE_ANON_KEY)) {
    throw Error('invalid enviroment variables')
  }

  const supabaseClient = createBrowserClient(SUPABASE_URL, SUPABASE_ANON_KEY)

  return supabaseClient
}
