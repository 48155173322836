import type { SVGProps } from 'react'
import React from 'react'
import isNil from 'lodash.isnil'

const WhatsAppIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill={isNil(props.color) ? '#fff' : props.color}
      fillRule="evenodd"
      d="M0 20.53l1.413-5.136a9.858 9.858 0 01-1.33-4.955C.087 4.975 4.554.53 10.043.53a9.98 9.98 0 017.043 2.905A9.891 9.891 0 0120 10.447c-.002 5.464-4.47 9.91-9.958 9.91h-.004a9.985 9.985 0 01-4.759-1.206L0 20.53zm5.524-3.173l.302.178a8.295 8.295 0 004.213 1.149h.003c4.562 0 8.275-3.696 8.277-8.238.002-4.55-3.703-8.24-8.274-8.243-4.565 0-8.278 3.695-8.28 8.237a8.188 8.188 0 001.265 4.383l.197.312-.836 3.04 3.133-.818zM6.993 5.87c.177.007.374.015.56.427.127.282.341.808.512 1.228.126.308.228.559.254.611.063.124.104.27.021.434a9.457 9.457 0 00-.035.072 1.401 1.401 0 01-.213.341c-.042.048-.085.1-.127.152a4.166 4.166 0 01-.247.282c-.124.124-.254.258-.109.506.145.247.645 1.058 1.384 1.715.796.706 1.487 1.005 1.837 1.156.068.03.124.053.164.073.249.124.394.103.54-.062.145-.165.621-.723.787-.97.166-.249.332-.207.56-.125.228.083 1.451.682 1.7.806l.137.066c.173.084.29.14.34.223.062.103.063.599-.145 1.178-.207.578-1.223 1.136-1.68 1.177l-.132.014c-.421.05-.954.112-2.853-.634-2.34-.918-3.881-3.195-4.198-3.662l-.053-.077-.002-.003c-.135-.18-1.014-1.349-1.014-2.559 0-1.14.564-1.738.823-2.013a6.22 6.22 0 00.048-.052.916.916 0 01.664-.31h.027c.157 0 .313 0 .45.006z"
      clipRule="evenodd"
    ></path>
  </svg>
)
export default WhatsAppIcon
