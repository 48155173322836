import { createListenerMiddleware } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { AppStartListening } from '@centrito/app/store'
import localStorageClient from '@centrito/app/utils/services/localStorage'

const persistHackMiddleware = createListenerMiddleware()

;(persistHackMiddleware.startListening as AppStartListening)({
  predicate: (action) => {
    const condition = ![
      '@@INIT',
      '__NEXT_REDUX_WRAPPER_HYDRATE__',
      'persist/PERSIST',
      'persist/REHYDRATE',
      'api/config/middlewareRegistered',
    ].includes(action.type)
    return condition
  },
  effect: (_, listenerApi) => {
    const state = listenerApi.getState()

    if (isNil(state.userData.cart?.items[0]?.product.product.pricingData.priceTotal)) {
      const fixedState = {
        ...state,
        userData: {
          ...state.userData,
          cart: {
            ...state.userData.cart,
            items: [],
          },
        },
      }
      const serializedState = JSON.stringify(
        Object.fromEntries(Object.entries(fixedState).map(([k, v]) => [k, JSON.stringify(v)])),
      )
      if (!isNil(localStorageClient)) localStorageClient.setItem('persist:root', serializedState)
    } else {
      const serializedState = JSON.stringify(
        Object.fromEntries(Object.entries(state).map(([k, v]) => [k, JSON.stringify(v)])),
      )
      if (!isNil(localStorageClient)) localStorageClient.setItem('persist:root', serializedState)
    }
  },
})

export default persistHackMiddleware
