import { createMedia } from '@tamagui/react-native-media-driver'
import { shorthands } from '@tamagui/shorthands'
import { themes, tokens } from '@tamagui/themes'
import { createFont, createTamagui } from 'tamagui'
import { animations } from './animations'
import { color } from './tokens'

const interFont = createFont({
  family: 'Inter',
  size: {
    4: 14,
    6: 14,
    true: 14,
  },
  weight: {
    6: '400',
    7: '700',
  },
  color: {
    6: '$colorFocus',
    7: '$color',
  },
  letterSpacing: {
    5: 2,
    6: 1,
    7: 0,
    8: -1,
    9: -2,
    10: -3,
    12: -4,
    14: -5,
    15: -6,
  },
  face: {
    400: { normal: 'Inter' },
    600: { normal: 'InterSemiBold' },
    700: { normal: 'InterBold' },
  },
})

const montserratFont = createFont({
  family: 'Montserrat',
  size: {
    4: 14,
    6: 14,
    true: 14,
  },
  weight: {
    6: '400',
    7: '700',
  },
  face: {
    400: { normal: 'Montserrat' },
    700: { normal: 'MontserratBold' },
  },
})

const poppinsFont = createFont({
  family: 'Poppins',
  size: {
    4: 14,
    6: 14,
    true: 14,
  },
  weight: {
    6: '400',
    7: '700',
  },
  face: {
    400: { normal: 'Poppins' },
    500: { normal: 'PoppinsMedium' },
    600: { normal: 'PoppinsSemiBold' },
    700: { normal: 'PoppinsBold' },
  },
})

const niramitFont = createFont({
  family: 'Niramit',
  size: {
    4: 14,
    6: 14,
    true: 14,
  },
  weight: {
    6: '400',
    7: '700',
  },
  face: {
    400: { normal: 'Niramit' },
    700: { normal: 'NiramitBold' },
  },
})

const latoFont = createFont({
  family: 'Lato',
  size: {
    4: 14,
    6: 14,
    true: 14,
  },
  weight: {
    6: '400',
    7: '700',
  },
  face: {
    400: { normal: 'Lato' },
    500: { normal: 'LatoMedium' },
    600: { normal: 'LatoSemiBold' },
    700: { normal: 'LatoBold' },
  },
})

export const config = createTamagui({
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  shorthands,
  fonts: {
    body: interFont,
    interFont,
    montserratFont,
    poppinsFont,
    niramitFont,
    latoFont,
  },
  themes,
  tokens: { ...tokens, color },
  media: createMedia({
    xs: { maxWidth: 660 },
    gtXs: { minWidth: 660 + 1 },
    sm: { maxWidth: 860 },
    gtSm: { minWidth: 860 + 1 },
    md: { maxWidth: 980 },
    gtMd: { minWidth: 980 + 1 },
    lg: { maxWidth: 1120 },
    gtLg: { minWidth: 1120 + 1 },
    short: { maxHeight: 820 },
    tall: { minHeight: 820 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
  defaultFont: 'body',
})
