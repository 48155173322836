import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from '@centrito/api'
import { transformer } from '@centrito/api/transformer'
import { ROOT_URL_API } from '@centrito/app/config'

export const trpcClientConfig = {
  transformer,
  links: [
    httpBatchLink({
      url: `${ROOT_URL_API}/trpc`,
    }),
  ],
}

const proxyClient = createTRPCProxyClient<AppRouter>(trpcClientConfig)

export default proxyClient
