import {
  ANALYTICS_ENABLED,
  META_ENABLED,
  validEventNames,
} from '@centrito/app/utils/services/analytics/meta/utils/constants'
import * as methods from './public'

export class MetaClient {
  analyticsEnabled = ANALYTICS_ENABLED
  clientEnabled = META_ENABLED
  validEventNames = validEventNames
  public captureCustomEvent = methods.captureCustomEvent
}

const metaClient = new MetaClient()

export default metaClient
