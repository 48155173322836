import posthog from 'posthog-js'
import { type PosthogClient } from '@centrito/app/utils/services/analytics/posthog'

export default function (
  this: PosthogClient,
  aliasId: string,
  isEnabled: boolean = this.analyticsEnabled,
): void {
  if (!isEnabled || !this.clientEnabled) return
  posthog.alias(aliasId)
}
