import { createAsyncThunk } from '@reduxjs/toolkit'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import { type AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const removeCouponWarning = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
  },
  undefined,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/removeCouponWarning`, async (_payload, thunkAPI) => {
  return { updatedCart: thunkAPI.getState().userData.cart }
})
export default removeCouponWarning
