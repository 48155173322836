import React, { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

export interface RouteContextInterface {
  isRouting: boolean
}

const initialContext: RouteContextInterface = {
  isRouting: false,
}

export const RouteContext = createContext<RouteContextInterface>(initialContext)

const RouteListener = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const router = useRouter()
  const scrollPositions = useRef<{ [url: string]: { [k: string]: number } }>({})
  const isBack = useRef(false)
  const [isRouting, setIsRouting] = useState(false)
  const CONTAINERS = ['catalog-content']

  useEffect(() => {
    const elements: { [k: string]: Element | null } = CONTAINERS.reduce(
      (acc, k) => ({ [k]: document.querySelector(`.${k}`), ...acc }),
      {},
    )

    router.beforePopState(() => {
      isBack.current = true
      return true
    })

    const handleStart = (): void => {
      setIsRouting(true)
      NProgress.start()
      const url = router.asPath
      // Scroll Logic
      scrollPositions.current[url] = {
        window: window.scrollY,
        ...Object.fromEntries(
          Object.entries(elements)
            .filter(([, el]) => el !== undefined)
            .map(([k, el]) => [k, el?.scrollTop] as [string, number]),
        ),
      }
    }
    const handleFulfill = (url: any): void => {
      posthogClient.capturePageView()
      setIsRouting(false)
      NProgress.done()

      // Scroll Logic
      if (isBack.current && scrollPositions.current[url] && !url.startsWith('/products/p')) {
        window.scroll({
          top: scrollPositions.current[url].window,
          behavior: 'auto',
        })
        Object.entries(scrollPositions.current[url])
          .filter(([k]) => k !== 'window')
          .forEach(([k, scrollPosition]) => {
            const el = elements[k]
            if (el?.scrollHeight && el.scrollHeight > scrollPosition) {
              el?.scroll({
                top: scrollPosition,
                behavior: 'auto',
              })
            }
          })
      } else if (url.startsWith('/products/p')) {
        // Reset scroll position to top for forward navigation
        window.scrollTo(0, 0)
        Object.values(elements).forEach((el) => {
          if (el) el.scrollTop = 0
        })
      }
      isBack.current = false
    }
    const handleStop = (): void => {
      setIsRouting(false)
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleFulfill)
    router.events.on('routeChangeError', handleStop)

    return (): void => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleFulfill)
      router.events.off('routeChangeError', handleStop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const memoizedIsRouting = useMemo(() => {
    return {
      isRouting,
    }
  }, [isRouting])

  return <RouteContext.Provider value={memoizedIsRouting}>{children}</RouteContext.Provider>
}

export default RouteListener
