import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface LoadAnonymousCartResponse {
  cart: CartExtendedPublicComposite
}

const loadAnonymousCart = createAsyncThunk<LoadAnonymousCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/loadAnonymousCart`,
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      const anonymousUserId = currentState.userData.anonymousUserId
      const cart = await trpcProxyClient.user.cart.replaceAnonymousCart.mutate({
        anonymousUserId: anonymousUserId!,
      })
      const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(cart)

      return {
        cart: { ...cart, pricingData },
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default loadAnonymousCart
