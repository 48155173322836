import React, { useState } from 'react'
import { type AppProps } from 'next/app'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import { getSupabaseBrowserClient } from '@centrito/app/utils/supabase'

const SupabaseProvider: React.FC<
  AppProps & {
    children: React.ReactNode
  }
> = ({ children, pageProps }) => {
  const [supabaseClient] = useState(() => getSupabaseBrowserClient())
  return (
    <SessionContextProvider
      supabaseClient={supabaseClient}
      initialSession={pageProps.initialSession}
    >
      {children}
    </SessionContextProvider>
  )
}

export default SupabaseProvider
