import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UserIdentifier } from '@centrito/api/nest/shopper/cart/domain/types/user-identifier'
import type { UpdateCartItemsPayload } from '@centrito/api/router/user/cart/computeCartItemPricing'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import metaClient from '@centrito/app/utils/services/analytics/meta'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

export interface UpdateCartItemsResponse {
  updatedCart: CartExtendedPublicComposite
  isDeactivatedCoupon?: boolean
  cartValidationError?: string
}

const captureShopperUpdateCartItemsEvent = ({
  quantityChange,
  phoneNumber,
}: {
  quantityChange: number
  phoneNumber?: string
}): void => {
  if (!isNil(phoneNumber)) {
    metaClient.captureCustomEvent('shopper_update_cart_items', { ph: phoneNumber })
  } else {
    metaClient.captureCustomEvent('shopper_update_cart_items')
  }
  posthogClient.captureCustomEvent('shopper_update_cart_items', { quantityChange })
}

const updateCartItem = createAsyncThunk<
  UpdateCartItemsResponse,
  UpdateCartItemsPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateCartItem`, async (payload, thunkAPI) => {
  try {
    const {
      product,
      productVariant,
      productPresentation,
      quantityChange,
      pricingData,
      isDeleteItem,
      cartCampaignRules,
    } = payload
    const targetItem = {
      productId: product.product.id,
      productVariantId: productVariant.variant.id,
      sizeName: productPresentation.name,
      priceDiscountItem: pricingData.priceItemsDiscount,
    }
    const currentState = thunkAPI.getState()
    const anonymousId = currentState.userData.anonymousUserId
    const userId = currentState.auth.authenticatedData?.userId
    const cartOwner = !isNil(userId)
      ? ({ type: 'AUTHENTICATED', userId } satisfies UserIdentifier)
      : !isNil(anonymousId)
      ? ({ type: 'ANONYMOUS', anonymousId } satisfies UserIdentifier)
      : undefined

    if (isNil(cartOwner)) {
      throw new Error('Cart owner not found')
    }
    const phoneNumber = currentState.auth.authenticatedData?.phone
    captureShopperUpdateCartItemsEvent({ quantityChange, phoneNumber })

    const { newCart, isDeactivatedCoupon, cartValidationError } =
      await trpcProxyClient.user.cart.updateCartItem.mutate({
        cartOwner,
        targetItem,
        quantityChange,
        isDeleteItem,
        cartCampaignRules,
      })
    const newPricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(newCart)

    return {
      updatedCart: { ...newCart, pricingData: newPricingData },
      isDeactivatedCoupon,
      cartValidationError,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateCartItem
