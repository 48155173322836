import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface OPTValidateResponse {
  redirectTo: string
  accessToken: string
  refreshToken: string
}

const devLogin = createAsyncThunk<OPTValidateResponse, any, AppThunkApiConfig>(
  `${REDUCER_NAMES.AUTH}/devLogin`,
  async (payload, thunkAPI) => {
    try {
      const response = await trpcProxyClient.auth.customer.devLogin.query({})
      if (isNil(response))
        return thunkAPI.rejectWithValue(miniSerializeError({ error: payload.message }))
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default devLogin
