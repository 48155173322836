import React from 'react'
import { PortalProvider } from '@gorhom/portal'

const CustomPortalProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <PortalProvider>{children}</PortalProvider>
}

export default CustomPortalProvider
