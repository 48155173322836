import React from 'react'
import type { SVGProps } from 'react'

export const WhatsAppIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#aw)">
      <circle cx={12} cy={12} r={12} fill="url(#bw)" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m6 18 .918-3.338a6.408 6.408 0 0 1-.864-3.22C6.056 7.89 8.96 5 12.527 5c1.717 0 3.364.68 4.578 1.888A6.43 6.43 0 0 1 19 11.447c-.002 3.551-2.905 6.442-6.473 6.442h-.002c-1.081 0-2.145-.27-3.094-.785L6 18Zm3.591-2.062.197.116a5.392 5.392 0 0 0 2.738.747h.002c2.965 0 5.379-2.402 5.38-5.355.001-2.958-2.407-5.357-5.378-5.358-2.968 0-5.38 2.402-5.382 5.354a5.323 5.323 0 0 0 .822 2.85l.129.202-.544 1.976 2.036-.532Zm.955-7.467c.115.004.243.01.364.278.082.183.222.525.333.797.081.2.148.364.165.398.04.08.067.175.013.282l-.023.046c-.04.082-.07.143-.138.223a6.026 6.026 0 0 0-.083.098c-.056.068-.111.135-.16.183-.08.08-.165.168-.07.329.094.161.418.688.899 1.115.517.459.966.653 1.194.751l.107.048c.161.08.255.067.35-.04.094-.108.404-.47.512-.631.108-.162.216-.135.364-.081.148.054.943.443 1.105.524l.089.043c.113.054.189.09.221.145.04.067.04.389-.094.765-.135.376-.795.739-1.092.765a3.471 3.471 0 0 0-.086.01c-.274.032-.62.072-1.855-.412-1.52-.597-2.522-2.077-2.728-2.381l-.034-.05-.002-.002c-.088-.117-.659-.877-.659-1.663 0-.741.366-1.13.535-1.309l.031-.034a.595.595 0 0 1 .432-.201h.017c.102 0 .204 0 .293.004Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient id="bw" x1={24} x2={24} y1={24} y2={0} gradientUnits="userSpaceOnUse">
        <stop stopColor="#20B038" />
        <stop offset={1} stopColor="#60D66A" />
      </linearGradient>
      <clipPath id="aw">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
