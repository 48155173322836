import React from 'react'
import type { SVGProps } from 'react'

export const InstagramIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#ai)">
      <circle cx={12} cy={12} r={12} fill="url(#bi)" />
      <path
        fill="#fff"
        d="M11.997 6.256c1.871 0 2.093.009 2.829.041.684.03 1.053.145 1.3.241.325.126.56.28.804.523.246.246.396.478.522.804.096.246.211.618.241 1.3.033.738.041.96.041 2.828 0 1.872-.008 2.093-.04 2.83-.031.683-.146 1.053-.242 1.299-.126.325-.279.56-.522.804a2.156 2.156 0 0 1-.805.523c-.246.095-.618.21-1.3.24-.738.033-.96.041-2.828.041-1.871 0-2.093-.008-2.829-.04-.684-.03-1.053-.146-1.3-.241a2.168 2.168 0 0 1-.804-.523 2.155 2.155 0 0 1-.522-.804c-.096-.246-.21-.619-.24-1.3-.034-.738-.042-.96-.042-2.829 0-1.87.008-2.093.041-2.828.03-.684.145-1.054.24-1.3.127-.326.28-.56.523-.804.247-.247.48-.397.805-.523.246-.096.618-.21 1.3-.24.735-.033.957-.042 2.828-.042Zm0-1.26c-1.901 0-2.14.007-2.886.04-.744.033-1.256.153-1.7.326-.462.18-.853.418-1.241.81-.391.388-.63.78-.81 1.239-.172.446-.293.955-.326 1.699-.032.75-.04.987-.04 2.889 0 1.901.008 2.14.04 2.886.033.744.154 1.256.326 1.7.18.461.419.853.81 1.241.388.389.78.63 1.24.807.445.173.954.293 1.698.326.747.033.985.04 2.886.04 1.902 0 2.14-.007 2.887-.04.744-.033 1.255-.153 1.699-.326.46-.178.85-.418 1.239-.807.388-.388.63-.78.807-1.24.172-.445.293-.954.325-1.698.033-.747.042-.985.042-2.886 0-1.902-.009-2.14-.041-2.887-.033-.744-.154-1.255-.326-1.699a3.281 3.281 0 0 0-.802-1.244 3.424 3.424 0 0 0-1.239-.808c-.446-.172-.955-.292-1.699-.325-.75-.036-.987-.044-2.889-.044Z"
      />
      <path
        fill="#fff"
        d="M11.997 8.401a3.599 3.599 0 0 0 0 7.196 3.598 3.598 0 0 0 0-7.196Zm0 5.932a2.334 2.334 0 1 1 0-4.668 2.334 2.334 0 0 1 0 4.668ZM16.577 8.259a.84.84 0 1 1-1.68 0 .84.84 0 0 1 1.68 0Z"
      />
    </g>
    <defs>
      <linearGradient
        id="bi"
        x1={2.752}
        x2={20.997}
        y1={2.5}
        y2={21.518}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.034} stopColor="#334FFF" />
        <stop offset={0.271} stopColor="#C838F7" />
        <stop offset={0.51} stopColor="#FF1F7A" />
        <stop offset={0.745} stopColor="#FF8534" />
        <stop offset={1} stopColor="#FFBD28" />
      </linearGradient>
      <clipPath id="ai">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
