import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import { $Enums } from '@centrito/db'
import { SHIPPING_CITIES } from '@centrito/api/config'
import type { AlliedStorePublic } from '@centrito/api/nest/platform/database/domain'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UpdateCartDeliveryDataInput } from '@centrito/api/nest/shopper/cart/domain/schemas'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import metaClient from '@centrito/app/utils/services/analytics/meta'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

interface UpdateUserAddressPayload {
  input: UpdateCartDeliveryDataInput
}

export interface UpdateUserAddressResponse {
  type: $Enums.OrderDeliveryDestinationType
  newCart?: CartExtendedPublicComposite
  alliedStore?: AlliedStorePublic | null
  isDeactivatedCoupon: boolean
}

const updateUserAddress = createAsyncThunk<
  UpdateUserAddressResponse,
  UpdateUserAddressPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateUserAddress`, async (payload, thunkAPI) => {
  try {
    const type = payload.input.type

    if (type === $Enums.OrderDeliveryDestinationType.LOCAL_ALLIED_STORE) {
      const [newCartResult, alliedStoreResult] = await Promise.all([
        trpcProxyClient.user.cart.updateDelivery.mutate({
          type,
          alliedStoreId: payload.input.alliedStoreId,
        }),
        trpcProxyClient.user.profile.updateLastAlliedStore.mutate({
          alliedStoreId: payload.input.alliedStoreId,
        }),
      ])

      return {
        type,
        alliedStore: alliedStoreResult.store,
        newCart: {
          ...newCartResult.newCart,
          pricingData: {
            ...newCartResult.newCart.pricingData,
            priceCartTotal:
              newCartResult.newCart.pricingData.priceCartTotal -
              newCartResult.newCart.pricingData.priceShippingRevenue,
            priceShippingRevenue: 0,
          },
        },
        isDeactivatedCoupon: newCartResult.isDeactivatedCoupon,
      }
    }

    const input = payload.input
    const isNational = !SHIPPING_CITIES.includes(input.address.city)

    const { newCart, isDeactivatedCoupon } = await trpcProxyClient.user.cart.updateDelivery.mutate({
      type: isNational
        ? $Enums.OrderDeliveryDestinationType.NATIONAL
        : $Enums.OrderDeliveryDestinationType.LOCAL_HOME,
      address: input.address,
    })

    const userPhoneNumber = thunkAPI.getState().auth.authenticatedData?.phone
    if (!isNil(userPhoneNumber)) {
      metaClient.captureCustomEvent('shopper_checkout_domicile_updated', {
        ph: userPhoneNumber,
      })
    } else {
      metaClient.captureCustomEvent('shopper_checkout_domicile_updated')
    }
    posthogClient.captureCustomEvent('shopper_checkout_domicile_updated')
    return { newCart, type, isDeactivatedCoupon }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateUserAddress
