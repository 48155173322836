import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UserIdentifier } from '@centrito/api/nest/shopper/cart/domain/types/user-identifier'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const addCoupon = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
    isUsingCouponAlready: boolean
  },
  ({ couponId: string; couponCode?: never } | { couponCode: string; couponId?: never }) & {
    isReplacingCoupon?: boolean
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/addCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const anonymousId = currentState.userData.anonymousUserId
  const userId = currentState.auth.authenticatedData?.userId

  const cartOwner = !isNil(userId)
    ? ({ userId, type: 'AUTHENTICATED' } satisfies UserIdentifier)
    : ({ anonymousId: anonymousId!, type: 'ANONYMOUS' } satisfies UserIdentifier)

  try {
    const resp = await trpcProxyClient.user.cart.addCoupon.mutate({ ...payload, cartOwner })
    const updatedCart = await trpcProxyClient.user.cart.get.query(cartOwner)

    return {
      isUsingCouponAlready: resp.isUsingCouponAlready,
      updatedCart,
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(miniSerializeError(e))
  }
})
export default addCoupon
