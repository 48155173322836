import React from 'react'
import type { SVGProps } from 'react'

export const TikTokIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#at)">
      <circle cx={12} cy={12} r={12} fill="#000" />
      <path
        fill="#FF004F"
        d="M15.02 10.053a5.462 5.462 0 0 0 3.194 1.026v-2.3c-.225 0-.45-.024-.67-.07v1.81a5.462 5.462 0 0 1-3.195-1.026v4.695a4.244 4.244 0 0 1-4.237 4.251 4.206 4.206 0 0 1-2.358-.719c.77.79 1.842 1.28 3.03 1.28a4.245 4.245 0 0 0 4.236-4.252v-4.695Zm.828-2.32a3.212 3.212 0 0 1-.828-1.878V5.56h-.636a3.22 3.22 0 0 0 1.464 2.173Zm-6.614 8.185a1.941 1.941 0 0 1-.396-1.177 1.942 1.942 0 0 1 2.526-1.854v-2.352a4.26 4.26 0 0 0-.67-.039v1.831a1.941 1.941 0 0 0-2.526 1.854c0 .76.433 1.417 1.066 1.737Z"
      />
      <path
        fill="#fff"
        d="M14.35 9.493a5.462 5.462 0 0 0 3.193 1.026v-1.81a3.204 3.204 0 0 1-1.695-.976 3.22 3.22 0 0 1-1.464-2.173h-1.67v9.187a1.942 1.942 0 0 1-1.938 1.939c-.63 0-1.188-.301-1.543-.768a1.945 1.945 0 0 1-1.066-1.737 1.941 1.941 0 0 1 2.527-1.854v-1.83a4.245 4.245 0 0 0-4.148 4.25c0 1.158.46 2.207 1.208 2.973.674.455 1.485.72 2.358.72a4.244 4.244 0 0 0 4.237-4.252V9.493Z"
      />
      <path
        fill="#00F2EA"
        d="M17.543 8.709v-.49c-.6.001-1.187-.167-1.696-.486a3.2 3.2 0 0 0 1.696.976Zm-3.16-3.149a3.262 3.262 0 0 1-.034-.264V5h-2.306v9.188a1.942 1.942 0 0 1-2.81 1.73c.354.467.913.768 1.543.768a1.942 1.942 0 0 0 1.938-1.938V5.56h1.67Zm-3.69 4.937v-.521a4.244 4.244 0 0 0-4.818 4.212c0 1.472.746 2.77 1.878 3.533a4.245 4.245 0 0 1-1.207-2.973 4.245 4.245 0 0 1 4.147-4.251Z"
      />
    </g>
    <defs>
      <clipPath id="at">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
