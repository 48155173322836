import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { $Enums } from '@centrito/db'
import { DiscountDataStatus } from '@centrito/common/constants'
import type { RootState } from '@centrito/app/store'

const selectSelf = (state: RootState): RootState => state

export const selectInAuthSession = createSelector(
  selectSelf,
  (state) => !!state.auth.authenticatedData,
)

export const selectAuthLoaded = createSelector(selectSelf, (state) => state.auth.authLoaded)

export const selectAuthenticatedData = createSelector(
  selectSelf,
  (state) => state.auth.authenticatedData,
)

export const selectIsAuthenticated = createSelector(
  selectSelf,
  (state) => !isNil(state.auth.authenticatedData),
)

export const merchantCatalogProfile = createSelector(
  selectSelf,
  (state) => state.merchantCustomerData.merchantCatalogProfile,
)

export const selectCartItems = createSelector(
  selectSelf,
  (state) => state.userData.cart?.items ?? [],
)

export const selectMerchantCartItems = createSelector(
  selectSelf,
  (state) => state.merchantCustomerData.cart.items,
)

export const selectCart = createSelector(selectSelf, (state) => state.userData.cart)

export const selectUserDiscountData = createSelector(
  selectSelf,
  (state) => state.userData.cart.cart.discountData,
)

export const selectMerchantName = createSelector(
  selectSelf,
  (state) => state.merchantData.merchantProfile?.name || null,
)
export const selectMerchantProfile = createSelector(
  selectSelf,
  (state) => state.merchantData.merchantProfile,
)

export const selectCartDeliveryAddress = createSelector(selectSelf, (state) => {
  const address = state.userData.cart?.cart?.deliveryData

  if (isNil(address) || isEmpty(address)) {
    return null
  }

  const nonEmptyAddress = address as Exclude<typeof address, Record<string, never>>
  return nonEmptyAddress
})

export const selectCartDeliveryDate = createSelector(selectSelf, (state) => {
  return state.userData.cart?.cart?.deliveryDate
})

export const selectUserDomicile = createSelector(selectSelf, (state) => {
  const domicile = state.userData.cart?.cart?.deliveryData

  if (isNil(domicile) || isEmpty(domicile)) {
    return null
  }

  return domicile
})

export const selectLastPickedStore = createSelector(selectSelf, (state) => {
  const lastPickedStore = state.userData.selectedStore

  if (isNil(lastPickedStore) || isEmpty(lastPickedStore) || !lastPickedStore.isActive) {
    return null
  }

  return lastPickedStore
})

export const selectCartPricingData = createSelector(
  selectSelf,
  (state) => state.userData.cart.pricingData,
)

export const selectUserId = createSelector(
  selectSelf,
  (state) => state.auth.authenticatedData?.userId || null,
)
export const selectUserGovernmentID = createSelector(
  selectSelf,
  (state) => state.userData.userProfile?.governmentId ?? null,
)

export const selectUserEmail = createSelector(
  selectSelf,
  (state) => state.userData.userProfile?.email ?? null,
)

export const selectIsRegistered = createSelector(
  selectSelf,
  (state) => !isNil(state.auth.authenticatedData) && state.auth.authenticatedData.isRegistered,
)
export const selectScrollingToTop = createSelector(
  selectSelf,
  (state) => state.userData.isScrollingToTop,
)

export const selectUserRole = createSelector(
  selectSelf,
  (state) => state.auth.authenticatedData?.role || null,
)

export const selectUserPhoneNumber = createSelector(
  selectSelf,
  (state) => state.auth.authenticatedData?.phone ?? null,
)

export const selectClientUserId = createSelector(
  selectSelf,
  (state) => state.userData.salesAssistant?.clientUserId,
)

export const selectUserProfile = createSelector(selectSelf, (state) => state.userData.userProfile)

export const selectAnonymousUserId = createSelector(
  selectSelf,
  (state) => state.userData.anonymousUserId,
)

export const selectAppLastOpenedAt = createSelector(
  selectSelf,
  (state) => state.userData.appLastOpenedAt,
)

export const selectSearchedTerms = createSelector(
  selectSelf,
  (state) => state.userData.searchedTerms,
)

export const selectDiscountUserPromotionId = createSelector(selectSelf, (state) => {
  const discountData = state.userData.cart?.cart?.discountData
  return discountData && discountData.campaigns.length > 0
    ? discountData.campaigns[0].userPromotionId
    : undefined
})

export const selectCartValidCampaignRules = createSelector(selectSelf, (state) => {
  const discountData = state.userData.cart?.cart?.discountData
  return discountData && discountData.campaigns.length > 0
    ? discountData.campaigns
        .filter((campaign) => campaign.status === DiscountDataStatus.IN_USE)
        .map((campaign) => campaign.rules)
    : []
})

export const selectDiscountCampaignErrorMessage = createSelector(selectSelf, (state) => {
  const discountData = state.userData.cart?.cart?.discountData
  return discountData && discountData.campaigns.length > 0
    ? discountData.campaigns[0].metadata?.errorMessage
    : undefined
})

export const selectDiscountPromotionType = createSelector(
  selectSelf,
  (state) => state.userData.cart?.cart?.discountData?.campaigns[0]?.rules.discount.type || '',
)

export const selectDiscountCampaign = createSelector(
  selectSelf,
  (state) => state.userData.cart?.cart?.discountData?.campaigns || [],
)

export const selectCategoryIndex = createSelector(
  selectSelf,
  (state) => state.userData.categoryIndex || 0,
)

export const selectCheckoutDeliveryType = createSelector(
  selectSelf,
  (state) => state.userData.checkout.deliveryStepState.selectedType ?? undefined,
)

export const selectCheckoutIsActionEnabled = createSelector(
  selectSelf,
  (state) => state.userData.checkout.isActionEnabled,
)

export const selectCheckoutFirstStepCompleteness = createSelector(
  selectCheckoutDeliveryType,
  selectUserDomicile,
  selectLastPickedStore,
  (deliveryType, domicile, store) => {
    if (isNil(deliveryType)) {
      return false
    }
    if (
      deliveryType === $Enums.OrderDeliveryDestinationType.LOCAL_HOME ||
      deliveryType === $Enums.OrderDeliveryDestinationType.NATIONAL
    ) {
      return !isNil(domicile)
    }
    if (deliveryType === $Enums.OrderDeliveryDestinationType.LOCAL_ALLIED_STORE) {
      return !isNil(store)
    }

    return false
  },
)

export const selectCheckoutPaymentMethod = createSelector(
  selectSelf,
  (state) => state.userData.checkout.paymentStepState.selectedMethod ?? undefined,
)

export const selectCheckoutPalommaBank = createSelector(
  selectSelf,
  (state) => state.userData.checkout.paymentStepState.selectedBank ?? null,
)

export const selectCheckoutPalommaBankName = createSelector(
  selectSelf,
  (state) => state.userData.checkout.paymentStepState.selectedBankName ?? null,
)

export const selectCheckoutDeliveryHighlightedState = createSelector(selectSelf, (state) => {
  const stepState = state.userData.checkout.deliveryStepState

  const { isHighlighted } = stepState

  return isHighlighted
})

export const selectCheckoutPaymentHighlightedState = createSelector(selectSelf, (state) => {
  const stepState = state.userData.checkout.paymentStepState

  const { isHighlighted } = stepState
  return isHighlighted
})

export const selectCompleteInformationState = createSelector(selectSelf, (state) => {
  const stepState = state.userData.checkout.completeInformationState
  const { isHighlighted, isComplete } = stepState

  return {
    isHighlighted,
    isComplete,
  }
})

export const selectSessionProductIds = createSelector(
  selectSelf,
  (state) => state.userData.sessionProductIds,
)

export const selectIsEmailNotificationEnabled = createSelector(
  selectSelf,
  (state) => state.userData.isEmailNotificationsEnabled,
)
