/**
 * Returns darkened color
 * @function
 * @param {string} hexColor - The color in hex format.
 * @return {string} The darkened color in hex format.
 */
export const darkenColor = (hexColor: string): string => {
  if (!hexColor.startsWith('#')) {
    throw new Error("The color must be a hex string. Example: '#000000'")
  }

  hexColor = hexColor.toString().replace('#', '')
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16)
    let r = (decimalColor >> 16) - 30
    r > 255 && (r = 255)
    r < 0 && (r = 0)
    let g = (decimalColor & 0x0000ff) - 30
    g > 255 && (g = 255)
    g < 0 && (g = 0)
    let b = ((decimalColor >> 8) & 0x00ff) - 30
    b > 255 && (b = 255)
    b < 0 && (b = 0)
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`
  } else {
    return hexColor
  }
}

// TODO: Move this to a better place
export type AnswerType = 'radio' | 'checkbox' | 'textarea'
export interface Question {
  title: string
  answerType: AnswerType
  isFirstQuestion?: boolean
  placeholder?: string
  options?: QuestionOption[]
}

export interface QuestionOption {
  title: string
  question?: Question
}

export const REJECT_ORDER_SURVEY: Question = {
  title: '¿Por qué no está disponible?',
  answerType: 'radio',
  isFirstQuestion: true,
  options: [
    {
      title: 'Ya no manejamos la referencia',
      question: {
        title: '¿Tienes alguna referencia similar?',
        answerType: 'checkbox',
        options: [
          {
            title: 'No tengo una referencia similar',
          },
        ],
      },
    },
    {
      title: 'Ya no manejamos ese color en esa referencia',
      question: {
        title: '¿Cuáles colores tienes disponibles?',
        answerType: 'checkbox',
        options: [
          { title: 'Verde' },
          { title: 'Azul oscuro' },
          { title: 'Blanco' },
          { title: 'Palo de rosa' },
          { title: 'Naranja' },
        ],
      },
    },
    {
      title: 'La tendré en pocos días',
      question: {
        title: '¿Cuándo tendrías la prenda?',
        answerType: 'radio',
        options: [
          { title: '1 día (Mañana)' },
          { title: '2 días' },
          { title: '3 días' },
          { title: '4 días' },
          { title: 'Me demoro más de 4 días' },
          { title: 'Me llega miércoles de madrugón' },
          { title: 'Me llega sábado de madrugón' },
        ],
      },
    },
    {
      title: 'El fabricante no tiene el material',
      question: {
        title: '¿Tienes alguna referencia similar?',
        answerType: 'checkbox',
        options: [
          {
            title: 'No tengo una referencia similar',
          },
        ],
      },
    },
    {
      title: 'Otro',
      question: {
        title: '¿Por qué no está disponible?',
        answerType: 'textarea',
        placeholder: 'Escribe el motivo por el cuál no tienes la prenda disponible',
      },
    },
  ],
}

export interface AccordionTriggerProps {
  open: boolean
}
