import isNil from 'lodash.isnil'
import posthog from 'posthog-js'
import { type PosthogClient } from '@centrito/app/utils/services/analytics/posthog'
import { POSTHOG_OPT_OUT } from './common'

export default function (
  this: PosthogClient,
  userId: string,
  properties: { phone: string | undefined; email: string | undefined },
  isEnabled: boolean = this.analyticsEnabled,
): void {
  if (!isEnabled || !this.clientEnabled) return
  posthog.identify(userId, properties)
  if (!isNil(properties.phone) && POSTHOG_OPT_OUT.includes(properties.phone)) {
    posthog.opt_out_capturing()
  }
}
