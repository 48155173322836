export const isServer = typeof window === 'undefined'

const DEFAULT_PROD_URL = process.env.NEXT_PUBLIC_PROD_URL ?? 'https://samsam.co'

export const getProdUrl = (): string | undefined => {
  // If running on the server, always use the default production URL
  if (isServer) {
    return DEFAULT_PROD_URL
  }

  const windowHost = window?.location?.host
  // If not on the server and windowHost is defined
  if (windowHost) {
    // Check if windowHost starts with 'www.' and return the corresponding URL
    if (windowHost.startsWith('www.samsam')) {
      return 'https://www.samsam.co'
    } else if (windowHost.startsWith('samsam')) {
      return 'https://samsam.co'
    } else if (windowHost.startsWith('www.centrito')) {
      return 'https://www.centrito.co'
    } else if (windowHost.startsWith('centrito')) {
      return 'https://centrito.co'
    } else if (windowHost.startsWith('www.tenderos.centrito')) {
      return 'https://www.tenderos.centrito.co'
    } else if (windowHost.startsWith('tenderos.centrito')) {
      return 'https://tenderos.centrito.co'
    } else {
      return undefined
    }
  }

  // If windowHost is not defined, fall back to environment variable or default URL
  return process.env.NEXT_PUBLIC_PROD_URL ?? 'https://samsam.co'
}

export const PROD_URL = getProdUrl()

export const DEPLOY_URL = process.env.NEXT_PUBLIC_DEPLOY_URL ?? ''
export const DEV_URL = 'http://localhost:3000' // NextJS dev url
export const PREVIEW_URL = 'https://www.staging.samsam.co'

export const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL ?? ''
export const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? ''

export const isDeployment = process.env.NODE_ENV === 'production'

export const isProd = isDeployment && DEPLOY_URL === DEFAULT_PROD_URL
export const isStaging = isDeployment && !!DEPLOY_URL && DEPLOY_URL !== DEFAULT_PROD_URL
export const isPreview = isDeployment && !(isProd || isStaging)
export const isDev = process.env.NODE_ENV === 'development'

export const isCloudBackend = SUPABASE_URL.startsWith('https://')
export const isLocalBackend = !isCloudBackend

export const ROOT_URL = isProd
  ? PROD_URL
  : isStaging
  ? DEPLOY_URL
  : isPreview
  ? PREVIEW_URL
  : DEV_URL
export const ROOT_URL_API = `${ROOT_URL}/api`

export const MERCHANT_ROOT_URL = `${ROOT_URL}/proveedores`

export const IS_MERCHANT_APP = ['https://www.centrito.co', 'https://centrito.co'].some(
  (url) => url === ROOT_URL,
)

// SEO config
export const TITLE = 'Samsam | Más simple, más confiable'
export const DESCRIPTION =
  'En Samsam comprar desde tu celular productos con precios justos, pago contra entrega y envío a toda Bogotá. Samsam | Más simple, más confiable !'

export const GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY ?? ''
export const GOOGLE_MAP_ID = process.env.NEXT_PUBLIC_GOOGLE_MAP_ID ?? ''

export const SITE_NAME = 'Samsam'
export const CENTRITO_WHATSAPP_NUMBER = '+573202247476'
export const HELP_BUTTON_WHATSAPP_NUMBER = '+573202247476'
export const MERCHANT_HELP_BUTTON_WHATSAPP_NUMBER = '+573013011555'
export const CENTRITO_LOGIN_NUMBER = '+573202247476'

export const COUPON_LENGTH = 6
export const NUM_PRODUCTS_ON_LOAD = 16
export const NUM_SUGGESTIONS_PRODUCTS = 4
export const COST_DOMICILIO = 8000
export const LOGISTICS_COST_STORE = 0
export const CACHE_DURATION_IN_SECONDS = 20 * 60 // 4 minutes
export const REVALIDATE_PRODUCT_PAGE_INTERVAL = 10 * 60 // 10 minutes
export const SHIPPING_CITIES = ['Bogotá', 'Soacha']

// place to keep regular expressions
export const REGEX_CATEGORY_NODE = /^[^|][A-Z_Ñ|]+[^|]$/
export const MAIN_COLOR_APP = '#ff004f'
export const DISABLE_LOGIN_IN_STAGING = true

// TODO: Make test accounts safer. This is useable in the frontend and there are no guards
export const DEFAULT_NON_PROD_TEST_ACCOUNT = process.env.NEXT_PUBLIC_TEST_ACCOUNT || '+576666666666'
export const DEFAULT_NON_PROD_TEST_MERCHANT =
  process.env.NEXT_PUBLIC_TEST_MERCHANT ?? 'test@centrito.co'

export enum PopUpContentTypes {
  DELIVERY = 'delivery',
  SCHEDULE = 'schedule',
}

export const DEEP_LINK_NEQUI_IOS = process.env.NEXT_PUBLIC_DEEP_LINK_NEQUI_IOS ?? ''

export const STATIC_PATHS_BUCKET = 'generated-static-paths'

export const IS_B2B = process.env.NEXT_PUBLIC_IS_B2B ?? 'false'

export const META_CONVERSION_API_VERSION = process.env.NEXT_PUBLIC_META_CONVERSION_API_VERSION
export const META_CONVERSION_PIXEL_ID = process.env.NEXT_PUBLIC_META_CONVERSION_PIXEL_ID
export const META_CONVERSION_ACCESS_TOKEN = process.env.NEXT_PUBLIC_META_CONVERSION_ACCESS_TOKEN

// TODO: Only run on prod?
export const FACEBOOK_PIXEL_SCRIPT_JS = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${META_CONVERSION_PIXEL_ID}');
  fbq('track', 'PageView');
`
export const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID
export const TIKTOK_API_ACCESS_TOKEN = process.env.NEXT_PUBLIC_TIKTOK_API_ACCESS_TOKEN
export const TIKTOK_PIXEL_SCRIPT_JS = `
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
  var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
  ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
    ttq.load('${TIKTOK_PIXEL_ID}');
    ttq.page();
  }(window, document, 'ttq');
`

export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? ''
export const ONE_SIGNAL_APP_ID = process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID ?? ''

export const PUSHWOOSH_APP_ID = process.env.NEXT_PUBLIC_PUSHWOOSH_APP_ID ?? ''
export const PUSHWOOSH_API_KEY = process.env.NEXT_PUBLIC_PUSHWOOSH_API_KEY ?? ''
