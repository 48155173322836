import { PrismaClient } from '@prisma/client'

export * from '@prisma/client'

const isDeployment = process.env.NODE_ENV === 'production'
const isServer = typeof window === 'undefined'
const globalForPrisma = global as unknown as { prisma: PrismaClient }

// https://www.prisma.io/docs/guides/database/troubleshooting-orm/help-articles/nextjs-prisma-client-dev-practices
const prisma = isServer
  ? globalForPrisma.prisma ||
    (isDeployment
      ? new PrismaClient({
          log: ['error'],
          datasources: {
            db: {
              url: process.env.DATABASE_PGBOUNCER_URL,
            },
          },
          // transactionOptions: {
          //   isolationLevel: Prisma.TransactionIsolationLevel.Serializable,
          //   maxWait: 5000, // default: 2000
          //   timeout: 10000, // default: 5000
          // },
        })
      : new PrismaClient({
          log: ['error', 'warn'],
        }))
  : globalForPrisma.prisma

if (!isDeployment) globalForPrisma.prisma = prisma

export default prisma
