import axios from 'axios'
import CryptoJS from 'crypto-js'
import isNil from 'lodash.isnil'
import posthog from 'posthog-js'
import {
  META_CONVERSION_ACCESS_TOKEN,
  META_CONVERSION_API_VERSION,
  META_CONVERSION_PIXEL_ID,
} from '@centrito/app/config'
import { type MetaClient } from '@centrito/app/utils/services/analytics/meta'

type CustomEventProperties = {
  ph?: string
  value?: number
  productId?: string
  categoryId?: string
  productName?: string
}

const getChecksum = (text: string): string => {
  const hash = CryptoJS.SHA256(text)
  return hash.toString(CryptoJS.enc.Hex)
}

function capture(options: {
  event_name: string
  ph?: string
  value?: number
  productId?: string
  categoryId?: string
  productName?: string
}): void {
  const { event_name, ph, value, productId, categoryId, productName } = options
  const apiVersion = META_CONVERSION_API_VERSION ?? ''
  const pixelId = META_CONVERSION_PIXEL_ID ?? ''
  const accessToken = META_CONVERSION_ACCESS_TOKEN ?? ''
  const apiUrl = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`
  const event_data = {
    data: [
      {
        action_source: 'website',
        event_name: event_name,
        event_time: Math.floor(Date.now() / 1000),
        ...(!isNil(value) ? { value: value } : {}),
        ...(!isNil(productId) ? { content_ids: productId } : {}),
        ...(!isNil(categoryId) ? { content_category: categoryId } : {}),
        ...(!isNil(productName) ? { content_name: productName } : {}),
        user_data: {
          client_user_agent: navigator.userAgent,
          external_id: posthog.get_distinct_id(),
          ...(!isNil(ph) ? { ph: ph } : {}),
        },
      },
    ],
  }

  axios.post(apiUrl, event_data).catch((error: any) => {
    console.error('Error al enviar el evento:', error)
  })
}

export default function (
  this: MetaClient,
  eventName: string,
  customEventProperties?: CustomEventProperties,
  isEnabled: boolean = this.analyticsEnabled,
): void {
  const validEventNames = this.validEventNames
  if (!isEnabled || !this.clientEnabled) {
    return
  }
  if (validEventNames.includes(eventName)) {
    const ph = customEventProperties?.ph
    if (eventName === 'shopper_order_created' && !isNil(ph)) {
      const value = customEventProperties?.value
      const phChecksum = getChecksum(ph)
      capture({ event_name: eventName, ph: phChecksum, value: value })
    } else if (eventName === 'shopper_pageview_product') {
      const productId = customEventProperties?.productId
      const categoryId = customEventProperties?.categoryId
      const productName = customEventProperties?.productName
      if (!isNil(ph)) {
        const phChecksum = getChecksum(ph)
        capture({
          event_name: eventName,
          ph: phChecksum,
          productId: productId,
          categoryId: categoryId,
          productName: productName,
        })
      } else {
        capture({
          event_name: eventName,
          productId: productId,
          categoryId: categoryId,
          productName: productName,
        })
      }
    } else if (!isNil(ph)) {
      const phChecksum = getChecksum(ph)
      capture({ event_name: eventName, ph: phChecksum })
    } else {
      capture({ event_name: eventName })
    }
  } else {
    console.log('Meta: Invalid Custom Event')
  }
}
