import React, { createContext } from 'react'

interface UrlContextInterface {
  url: string | null
}

const initialContext: UrlContextInterface = {
  url: null,
}

export const UrlContext = createContext<UrlContextInterface>(initialContext)

const UrlProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>
}

export default UrlProvider
