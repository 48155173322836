import React from 'react'
import { Linking } from 'react-native'
import { Button } from '@centrito/ui/src'
import { FeatureBoldText } from '@centrito/ui/src/components/text/new'
import { HELP_BUTTON_WHATSAPP_NUMBER } from '@centrito/ui/src/config'
import { WhatsAppIcon } from './WhatsAppIcon'

export const WhatsAppHelpButton: React.FC = () => {
  return (
    <Button
      backgroundColor="$mainShopperBlue"
      borderRadius={9999}
      padding={10}
      iconAfter={WhatsAppIcon}
      onPress={(): Promise<void> =>
        Linking.openURL(
          `https://wa.me/${HELP_BUTTON_WHATSAPP_NUMBER}?text=Hola,%20necesito%20ayuda%20con%20la%20app%20de%20*Samsam*%20`,
        )
      }
      hoverStyle={{
        backgroundColor: '$mainShopperBlue',
      }}
      pressStyle={{
        backgroundColor: '$mainShopperBlue',
      }}
    >
      <FeatureBoldText fontSize={14} color="$baseWhite">
        ¿Necesitas ayuda?
      </FeatureBoldText>
    </Button>
  )
}
