export const POSTHOG_OPT_OUT: string[] = [
  '573115721031', // Alejandro Ortiz Abello
  '573012208193', // Alejandro Villamil
  '573506625099', // Ana Maria Romero
  '573203952121', // Angela centrito
  '573024169329', // Angela Vargas
  '573133824227', // Angie Duque
  '573013011555', // Brayan Obispo
  '573212344172', // Briam Leonardo Palacios
  '573105532646', // Camila CX
  '573108188209', // Caro Merchan
  '573157369172', // Daniel Alejandro
  '573125039078', // Daniela Sanchez
  '573115823207', // Diana Caviativa
  '573043873772', // Geisson Ponce
  '573007382626', // Javier Mendez
  '573017875594', // Juan Diego Sastoque
  '573143176911', // Juan Olaya
  '573012073878', // Fransisco Escobar
  '573006701153', // Pao
  '573145650525', // Paola Romero
  '573197884098', // Paula Quintana
  '573174197324', // Sheyla Audor
  '573212204312', // Stefanny
  '573204473653', // Valentina Perez
  '573105803500', // Yudi
  '573186969475', // Daniel Aguilera
]
