import type { ComponentProps, RefObject } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import Router from 'next/router'
import type { FlatList, ScrollView } from 'react-native'
import { Platform } from 'react-native'

const windowScrollMemories: { [asPath: string]: number } = {}
const scrollViewMemories: {
  [asPath: string]: {
    scrollY: number
    isPop: boolean
  }
} = {}

/**
 * The following is taken/edited from `useScrollToTop` from `@react-navigation/native`
 */
type ScrollOptions = { y?: number; animated?: boolean }

type ScrollableView =
  | { scrollToTop(): void }
  | { scrollTo(options: ScrollOptions): void }
  | { scrollToOffset(options: { offset?: number; animated?: boolean }): void }
  | { scrollResponderScrollTo(options: ScrollOptions): void }

type ScrollableWrapper =
  | { getScrollResponder(): React.ReactNode }
  | { getNode(): ScrollableView }
  | ScrollableView

function getScrollableNode(ref: React.RefObject<ScrollableWrapper>) {
  if (ref.current == null) {
    return null
  }

  if (
    'scrollTo' in ref.current ||
    'scrollToOffset' in ref.current ||
    'scrollResponderScrollTo' in ref.current
  ) {
    // This is already a scrollable node.
    return ref.current
  } else if ('getScrollResponder' in ref.current) {
    // If the view is a wrapper like FlatList, SectionList etc.
    // We need to use `getScrollResponder` to get access to the scroll responder
    return ref.current.getScrollResponder()
  } else if ('getNode' in ref.current) {
    // When a `ScrollView` is wraped in `Animated.createAnimatedComponent`
    // we need to use `getNode` to get the ref to the actual scrollview.
    // Note that `getNode` is deprecated in newer versions of react-native
    // this is why we check if we already have a scrollable node above.
    return ref.current.getNode()
  } else {
    return ref.current
  }
}
/**
 * End of react-navigation code.
 */

type OnScroll = NonNullable<ComponentProps<typeof ScrollView | typeof FlatList>['onScroll']>

let isPop = false

let lastPath = ''

/**
 * @param scrollViewRef The `ref` passed to your `ScrollView`.
 */
function useScroller(
  scrollViewRef: RefObject<ScrollableWrapper>,
  {
    scrollDelay: _scrollDelay = 350,
    shouldAnimateScroll: _shouldAnimateScroll = (scrollY) => scrollY < 3000,
  }: {
    /**
     * Number of milliseconds the page should wait before triggering `scrollTo`.
     *
     * This allows content to render before it requires a scroll
     *
     * Default: `350`. Set it to a larger number if you expect a long
     *
     * It also accepts a function that returns a number. The function receives `scrollY`.
     *
     * For example:
     *
     * `scrollDelay = (scrollY) => 350 + scrollY / 10`
     */
    scrollDelay?: number | ((scrollY: number) => number)
    /**
     * Determine whether or not the scroll should animate.
     *
     * You can either pass a `boolean`, or a function which returns a boolean.
     *
     * If you pass a function, it will receive the `scrollY` as its only argument. This lets you determine if it should animate based on how far it's scrolling.
     *
     * By default, this value is `true` as long as the scroll is under 3000px, but you might want to do something like this:
     *
     * `(scrollToY) => scrollToY < 2000`
     *
     * This means your scroll will be animated, as long as the `scrollToY` value is under 2000. If it's too long, it can get a bit choppy with the animation.
     */
    shouldAnimateScroll?: boolean | ((scrollY: number) => boolean)
  } = {},
) {
  const hasScrolled = useRef(false)

  const scrollDelay = useRef(_scrollDelay)
  const shouldAnimateScroll = useRef(_shouldAnimateScroll)
  useEffect(() => {
    shouldAnimateScroll.current = _shouldAnimateScroll
    scrollDelay.current = _scrollDelay
  })

  useEffect(
    function maybeRestoreScroll() {
      if (Platform.OS !== 'web' || typeof window === 'undefined') return

      // de-dupe scrolling more than once on a given mount
      if (hasScrolled.current) return

      // this is mostly taken from react-navigation useScrollToTop
      let timeout = 0
      requestAnimationFrame(() => {
        const path = Router.asPath
        const memory = scrollViewMemories[path]

        if (!memory) return

        const { scrollY, isPop } = memory

        if (!isPop || !scrollY) return

        const animated =
          typeof shouldAnimateScroll.current === 'function'
            ? shouldAnimateScroll.current(scrollY)
            : shouldAnimateScroll.current

        const delay =
          typeof scrollDelay.current === 'function'
            ? scrollDelay.current(scrollY)
            : scrollDelay.current

        timeout = window.setTimeout(() => {
          const scrollable = getScrollableNode(scrollViewRef) as ScrollableWrapper

          if ('scrollTo' in scrollable) {
            scrollable.scrollTo({ y: scrollY, animated })

            hasScrolled.current = true
          } else if ('scrollToOffset' in scrollable) {
            scrollable.scrollToOffset({ offset: scrollY, animated })

            hasScrolled.current = true
          } else if ('scrollResponderScrollTo' in scrollable) {
            scrollable.scrollResponderScrollTo({ y: scrollY, animated })

            hasScrolled.current = true
          }

          if (hasScrolled.current) {
            scrollViewMemories[path].isPop = false
          }
        }, delay)
      })

      return () => {
        clearTimeout(timeout)
      }
    },
    [scrollViewRef],
  )

  /**
   * Update the scroll position whenever we scroll. This must be passed to your `ScrollView`.
   */
  const onScroll = useCallback<OnScroll>(({ nativeEvent }) => {
    if (Platform.OS === 'web') {
      scrollViewMemories[Router.asPath] = {
        ...scrollViewMemories[Router.asPath],
        scrollY: nativeEvent.contentOffset.y,
      }
    }
  }, [])

  return {
    onScroll,
  }
}

/**
 * This function should be called in `pages/_app.tsx`, outside of render code.
 *
 * https://github.com/vercel/next.js/issues/1309
 * https://github.com/vercel/next.js/issues/1309#issuecomment-690957041
 */
function nextjsScrollPositionRestorer() {
  if (process.browser) {
    window.history.scrollRestoration = 'manual'
    window.onpopstate = () => {
      isPop = true
    }
  }

  Router.events.on('routeChangeStart', () => {
    saveScroll()
    lastPath = Router.asPath
  })

  Router.events.on('routeChangeComplete', () => {
    const currentPath = Router.asPath
    const currentUrl = new URL(window.location.origin + currentPath)
    const currentBasePath = currentUrl.pathname
    const currentQueryParams = new URLSearchParams(currentUrl.search)

    const lastUrl = new URL(window.location.origin + lastPath)
    const lastBasePath = lastUrl.pathname
    const lastQueryParams = new URLSearchParams(lastUrl.search)

    if (isPop) {
      restoreWindowScroll()
      setScrollViewPositionPopStatus(currentPath, true)
      isPop = false
    } else {
      // Comprueba si la ruta base es la misma y si el único parámetro que cambió es 'p')
      if (
        (currentBasePath === lastBasePath &&
          currentQueryParams.get('p') !== lastQueryParams.get('p') &&
          Array.from(currentQueryParams.keys()).every(
            (key) => key === 'p' || currentQueryParams.get(key) === lastQueryParams.get(key),
          )) ||
        currentBasePath.includes('/products/n') ||
        currentBasePath.includes('/products/p')
      ) {
        return
      }

      setScrollViewPositionPopStatus(currentPath, false)
      windowScrollToTop()
    }
  })

  function saveScroll() {
    windowScrollMemories[Router.asPath] = window.scrollY
  }

  function setScrollViewPositionPopStatus(path: string, isPop: boolean) {
    scrollViewMemories[path] = {
      ...scrollViewMemories[path],
      isPop,
    }
  }

  function restoreWindowScroll() {
    const prevWindowScrollY = windowScrollMemories[Router.asPath]
    if (prevWindowScrollY !== undefined) {
      window.requestAnimationFrame(() => setTimeout(() => window.scrollTo(0, prevWindowScrollY), 0))
    }
  }

  function windowScrollToTop() {
    window.requestAnimationFrame(() => window.scrollTo(0, 0))
  }
}

const ReactNativeNextJsScrollRestore = {
  initialize: nextjsScrollPositionRestorer,
  useScroller,
}

export default ReactNativeNextJsScrollRestore
