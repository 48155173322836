import React from 'react'
import { Spinner, Stack } from '@centrito/ui/src'

const CenterSpinLoading: React.FC = () => (
  <Stack alignItems="center" justifyContent="center" flex={1}>
    <Spinner size="large" color="$samsamBlue" />
  </Stack>
)

export default CenterSpinLoading
