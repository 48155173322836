import posthog from 'posthog-js'
import { type PosthogClient } from '@centrito/app/utils/services/analytics/posthog'
import type { FeedType } from '@centrito/app/utils/services/analytics/posthog/utils/FeedType'

export default function (
  this: PosthogClient,
  feedType: FeedType,
  productId: string,
  productName: string,
  position: number,
  isEnabled: boolean = this.analyticsEnabled,
): void {
  if (!isEnabled || !this.clientEnabled) return
  posthog?.capture('shopper_product_click', {
    feedType,
    productId,
    productName,
    position,
  })
}
