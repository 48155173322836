import type { SVGProps } from 'react'
import React from 'react'

export const SadSamSamLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
    <path
      fill="#4C6EEC"
      d="M29.937 59.341c-1.155 2.318-2.728 4.08-4.863 5.334-7.34 4.286-16.815-.585-17.582-9.057-.061-.661-.069-1.33-.069-1.998-.007-5.858.016-11.717-.007-17.575-.023-5.554 1.573-10.57 5.129-14.862 3.86-4.658 8.821-7.386 14.854-8.092 4.62-.54 8.974.311 13.084 2.47.1.052.206.09.342.151 2.28-1.238 4.688-2.12 7.264-2.485 7.165-1.025 13.48.829 18.829 5.73 4.186 3.83 6.511 8.639 7.111 14.277.122 1.177.061 2.355.061 3.54-.03 6.064-.007 12.12-.015 18.183 0 5.85-5.349 11.17-11.2 11.23-4.961.053-8.593-2.09-11.01-6.382-.053-.091-.121-.183-.25-.373-2.318 4.4-5.927 6.854-10.813 6.793-4.87-.053-8.57-2.363-10.865-6.884Z"
    />
    <path
      fill="#FEF1E9"
      d="M43.348 47.982c-.532-.076-.844-.342-.995-.889-.259-.927-.86-1.444-1.55-1.429-.707.008-1.307.525-1.611 1.391-.35.988-.547 1.094-1.543.828-.281-.266-.372-.6-.327-.988.198-1.58 1.672-2.955 3.26-3.047 1.68-.098 3.115.98 3.54 2.637.168.692.062 1.231-.774 1.497ZM50.004 43.833a.862.862 0 0 1-.6-1.482c.06-.06 6.428-6.07 12.72-.205.349.327.364.867.045 1.216a.863.863 0 0 1-1.216.046c-2.143-1.999-4.521-2.584-7.066-1.733-1.953.654-3.268 1.9-3.275 1.915a.925.925 0 0 1-.608.243ZM19.968 43.765a.862.862 0 0 1-.6-1.482c.06-.06 6.428-6.07 12.72-.205.349.327.364.866.045 1.216a.863.863 0 0 1-1.216.045c-5.083-4.741-10.136-.015-10.349.19a.884.884 0 0 1-.6.236ZM21.951 31.638c-.015 1.9-1.261 3.54-3.009 4.133a4.468 4.468 0 0 1-5.433-2.21c-.357-.707-.554-1.46-.456-2.242.061-.494.22-.988.41-1.451.555-1.36 1.338-2.6 2.128-3.83.456-.714.935-1.413 1.398-2.112.03-.046.061-.1.1-.145.28-.357.577-.357.835.016 1.11 1.648 2.241 3.29 3.153 5.06.372.714.707 1.444.851 2.25.008.045.023.09.023.136v.395Zm-.669.167c.008-.357-.197-.593-.494-.623-.258-.03-.509.167-.562.456-.015.068-.015.144-.03.213-.038.174-.053.357-.114.532-.22.676-.654 1.17-1.254 1.534-.273.168-.334.48-.175.745a.51.51 0 0 0 .73.167c1.17-.691 1.77-1.74 1.9-3.024Z"
    />
  </svg>
)
