import isNil from 'lodash.isnil'
import type { SupabaseUser } from '@centrito/api/nest/auth/domain/schemas'
import { IS_MERCHANT_APP } from '@centrito/app/config'
import type { AuthenticatedData } from '@centrito/app/store/slices/auth/schema'

export const getAuthenticatedData = (user: SupabaseUser): AuthenticatedData | null => {
  const isMerchantPortal = IS_MERCHANT_APP
  const isRegistered = isMerchantPortal
    ? user.user_metadata.isRegistered.merchant
    : user.user_metadata.isRegistered.shopper
  return isNil(user.phone)
    ? null
    : {
        userId: user.id,
        phone: user.phone,
        isRegistered,
        role: user.user_metadata.role,
      }
}
