import React from 'react'
import { Text, type TextProps } from 'tamagui'

export const ButtonText: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontFamily="$poppinsFont" fontSize={13} fontWeight="700" {...rest}>
      {children}
    </Text>
  )
}
