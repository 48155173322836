import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { GovernmentIDType } from '@centrito/db'
import type { UserProfilePublic } from '@centrito/api/nest/platform/database/domain'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

interface UpdateUserDataPayload {
  newGovernmentIdType: GovernmentIDType
  newGovernmentId: string
  newEmail?: string
}

export interface UpdateUserDataResponse {
  newUserInfo: UserProfilePublic
}

const updateUserGovernmentId = createAsyncThunk<
  UpdateUserDataResponse,
  UpdateUserDataPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateUserGovernmentId`, async (payload, thunkAPI) => {
  try {
    const { newUserInfo } = await trpcProxyClient.user.profile.updateGovernmentId.mutate({
      governmentIdType: payload.newGovernmentIdType,
      governmentId: payload.newGovernmentId,
      email: payload.newEmail ?? '',
    })

    return { newUserInfo }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateUserGovernmentId
